<template>
  <div class="filter-bar">
    <div class="filter-dropdown" v-if="isFilter">
      <b-nav-item-dropdown
        id="dropdown-grouped"
        variant="link"
        class="dropdown-filter"
        left
      >
        <template #button-content>
          <span class="">{{ $t("Filter") }}</span>
          <feather-icon icon="ChevronDownIcon" />
        </template>
        <slot/>
      </b-nav-item-dropdown>
    </div>
    <div class="filter-search" v-if="isFilterSearch">
      <input
        class=""
        autocomplete="off"
        type="text"
        name="search"
        :placeholder="$t('Search…')"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
      />
    </div>
    <div class="filter-reset" v-if="isFilterSearch">
      <button class="" type="button" @click="$emit('reset')">
        {{ $t("Reset") }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    modelValue: String,
    isFilter: {
      type: Boolean,
      default: true,
    },
    isFilterSearch: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:modelValue", "reset"],
};
</script>
