<template>
  <div class="b-modal p-1">
    <validation-observer ref="simpleRules">
      <div class="row mb-1">
        <div class="col-md-6">
          <validation-provider #default="{ errors }" name="To" rules="required">
            <TextInput :required="true" :label="$t('To')" v-model="form.to" />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </div>
      </div>
      <label for="cars"> {{ $t("Data") }} </label>
      <MonacoEditor
        width="100%"
        height="330"
        theme="vs-dark"
        :language="'json'"
        :value="jsonData"
        @change="onEditorChange"
        :options="options"
      ></MonacoEditor>
      <div class="c-modal-footer d-flex align-items-center justify-content-end">
        <b-button variant="primary" @click="generateEmail">
          <span>{{ $t("Send Mail") }}</span>
        </b-button>
      </div>
    </validation-observer>
  </div>
</template>
<script>
import TextInput from "@/components/TextInput.vue";
import ElementIcon from "@/components/elements/Icon.vue";
import { BLink, BRow, BCol, BImg, BPagination } from "bootstrap-vue";
import MonacoEditor from "monaco-editor-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
export default {
  components: {
    ElementIcon,
    BRow,
    BCol,
    BLink,
    BImg,
    BPagination,
    MonacoEditor,
    TextInput,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    jsonData: {
      type: String,
      default: "",
    },
    to: {
      type: String,
      default: "",
    },
    invoiceTemplate: {
      type: Object,
      default: {},
    },
  },
  mounted() {
    this.form.to = this.to;
    this.form.data = this.jsonData;
  },
  data() {
    return {
      options: {
        // Monaco editor options (optional)
        automaticLayout: true,
        minimap: { enabled: false },
      },
      form: {
        to: "",
        data: "",
      },
    };
  },
  methods: {
    onEditorChange(newValue) {
      this.form.data = newValue;
    },
    async generateEmail() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          let to = [];
          to = this.form.to ? [this.form.to] : [];
          const payload = {
            data: this.getParsedJson(this.form.data),
            id: this.invoiceTemplate?.mailTemplateId ?? "",
            mails: to,
            from: this.invoiceTemplate?.senderMail ?? "",
            cc: this.invoiceTemplate?.cc ? [this.invoiceTemplate?.cc] : [],
            bcc: this.invoiceTemplate?.bcc ? [this.invoiceTemplate?.bcc] : [],
          };
          // send the mail
          await this.$store.dispatch("mailTemplates/sendMailTemplate", payload);
        }
      });
    },
    getParsedJson(data) {
      try {
        return JSON.parse(data);
      } catch (e) {
        return {};
      }
    },
  },
};
</script>
